import { Li, Span, Text, Ul, type UseModalState } from "@clipboard-health/ui-react";
import { Button, Stack } from "@mui/material";
import { CommonDialog } from "@src/appV2/lib";
import pluralize from "pluralize";

interface ShiftInviteMissingCoreRequiredDocumentsDialogProps {
  modalState: UseModalState;
  missingDocuments: string[];
  onClose: () => void;
}

export function ShiftInviteMissingCoreRequiredDocumentsDialog(
  props: Readonly<ShiftInviteMissingCoreRequiredDocumentsDialogProps>
) {
  const { modalState, missingDocuments, onClose } = props;
  const pluralizedDocument = pluralize("document", missingDocuments.length);

  return (
    <CommonDialog
      modalState={modalState}
      title={
        <>
          You&apos;re missing{" "}
          <Span color={(theme) => theme.palette.primary.main}>{missingDocuments.length}</Span>{" "}
          {pluralizedDocument}
        </>
      }
      actions={
        <Button fullWidth variant="outlined" onClick={onClose}>
          Go Back
        </Button>
      }
    >
      <Stack spacing={2}>
        <Text paragraph>
          You are missing the following {pluralizedDocument} required by the state or Clipboard:
        </Text>
        <Ul>
          {missingDocuments.slice(0, 6).map((document) => (
            <Text key={document} bold variant="body2">
              <Li>{document}</Li>
            </Text>
          ))}
        </Ul>
        <Text paragraph>Please upload the above {pluralizedDocument} to accept this invite.</Text>
      </Stack>
    </CommonDialog>
  );
}
