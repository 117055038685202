import { Text, type UseModalState } from "@clipboard-health/ui-react";
import { Button, Stack } from "@mui/material";
import { useGetAttendanceScoreProfile } from "@src/appV2/AttendanceScore/api/useGetAttendanceScoreProfile";
import { getScoreType } from "@src/appV2/AttendanceScore/utils";
import { CommonDialog } from "@src/appV2/lib";
import { useReliabilityRankingExperiment } from "@src/appV2/ReliabilityRank/useReliabilityRankExperiment";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";

interface ShiftInviteWeeklyHoursLimitExceededDialogProps {
  modalState: UseModalState;
  onConfirm: () => void;
  onClose: () => void;
  weeklyHoursLimit: number;
  isClipboardScoreEnabled: boolean;
}

export function ShiftInviteWeeklyHoursLimitExceededDialog(
  props: ShiftInviteWeeklyHoursLimitExceededDialogProps
) {
  const { modalState, onConfirm, onClose, weeklyHoursLimit, isClipboardScoreEnabled } = props;
  const { userId } = useDefinedWorker();
  const { data: attendanceScoreProfile } = useGetAttendanceScoreProfile(userId);

  const reliabilityRankingExperiment = useReliabilityRankingExperiment({
    reliabilityScore: attendanceScoreProfile?.reliabilityScore,
    reliabilityScoreRank: attendanceScoreProfile?.reliabilityScoreRank,
  });

  const scoreType = getScoreType({
    isReliabilityRankingExperimentEnabled: reliabilityRankingExperiment.enabled,
    isClipboardScoreEnabled,
  });

  return (
    <CommonDialog
      modalState={modalState}
      title="You have reached the weekly hours limit at this workplace"
      actions={
        <>
          <Button fullWidth variant="contained" color="primary" onClick={onConfirm}>
            View My Shifts
          </Button>
          <Button fullWidth variant="outlined" onClick={onClose}>
            Go Back
          </Button>
        </>
      }
    >
      <Stack spacing={1}>
        <Text paragraph>
          This workplace does not allow professionals to book more than {weeklyHoursLimit} hours of
          work per week. You can cancel your other booked shifts at this workplace to accept this
          invite ({scoreType} penalties may apply).
        </Text>
      </Stack>
    </CommonDialog>
  );
}
