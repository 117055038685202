import { IonPage } from "@ionic/react";
import { Alert } from "@mui/material";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";

import { AppBarHeader, BackButtonLink, PageWithHeader } from "../../lib";
import { useWorkerPendingShiftInvites } from "./api/useWorkerPendingShiftInvites";
import { EmptyShiftInvites } from "./EmptyShiftInvites";
import { ShiftInviteCardSkeleton } from "./ShiftInviteCardSkeleton";
import { ShiftInvites } from "./ShiftInvites";
import { type ShiftInvite } from "./types";

export function ShiftInvitesPage() {
  const {
    data: shiftInvitesData,
    isLoading: isPendingShiftInvitesLoading,
    isSuccess: isPendingShiftInvitesSuccess,
    isError: isPendingShiftInviteError,
    refetch: refetchShiftInvites,
  } = useWorkerPendingShiftInvites();
  const worker = useDefinedWorker();
  const shiftInvites: ShiftInvite[] = shiftInvitesData?.data ?? [];

  return (
    <IonPage>
      <PageWithHeader
        appBarHeader={
          <AppBarHeader
            title="Shift Invites"
            leftCta={<BackButtonLink defaultBackTo={DeprecatedGlobalAppV1Paths.MY_SHIFTS} />}
          />
        }
        onRefresh={refetchShiftInvites}
      >
        {isPendingShiftInvitesLoading && <ShiftInviteCardSkeleton />}
        {isPendingShiftInviteError && <Alert severity="error">Error loading shift invites.</Alert>}
        {isPendingShiftInvitesSuccess &&
          (shiftInvites.length > 0 ? (
            <ShiftInvites shiftInvites={shiftInvites} worker={worker} />
          ) : (
            <EmptyShiftInvites />
          ))}
      </PageWithHeader>
    </IonPage>
  );
}
