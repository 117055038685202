import { InternalLink, Text } from "@clipboard-health/ui-react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Box, Card, CardActionArea, CardContent, Stack } from "@mui/material";
import { RootPaths } from "@src/appV2/App/paths";
import { APP_V2_APP_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { useWorkerPendingShiftInvites } from "@src/appV2/Shifts/ShiftInvites/api/useWorkerPendingShiftInvites";
import { capitalize } from "lodash";
import pluralize from "pluralize";
import { useLocation } from "react-router-dom";

export function PendingShiftInvitesCount() {
  const { data: shiftInvites, isSuccess } = useWorkerPendingShiftInvites();
  const { pathname } = useLocation();
  const pendingShiftInvites = isSuccess ? shiftInvites.data : [];
  const shiftInviteCount = pendingShiftInvites.length;

  return shiftInviteCount > 0 ? (
    <Box padding={2}>
      <Card variant="outlined">
        <CardActionArea
          component={InternalLink}
          to={`${RootPaths.APP_V2_HOME}/myShifts/shiftInvites`}
          onClick={() => {
            logEvent(APP_V2_APP_EVENTS.SHIFT_INVITE_PENDING_COUNT_CLICK, {
              pageName: capitalize(pathname.split("/").pop()),
              shiftInviteCount,
            });
          }}
        >
          <CardContent>
            <Stack direction="row" alignItems="center" spacing={2}>
              <AccessTimeIcon />
              <Text>
                {shiftInviteCount} pending shift {pluralize("invite", shiftInviteCount)} to review
              </Text>
            </Stack>
          </CardContent>
        </CardActionArea>
      </Card>
    </Box>
  ) : null;
}
