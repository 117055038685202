import { Text, type UseModalState } from "@clipboard-health/ui-react";
import { Button, Stack } from "@mui/material";
import { useGetAttendanceScoreProfile } from "@src/appV2/AttendanceScore/api/useGetAttendanceScoreProfile";
import { getScoreType } from "@src/appV2/AttendanceScore/utils";
import { CommonDialog } from "@src/appV2/lib";
import { useReliabilityRankingExperiment } from "@src/appV2/ReliabilityRank/useReliabilityRankExperiment";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";

import { type Shift } from "../../Shift/types";

interface ShiftInviteConflictDialogProps {
  modalState: UseModalState;
  onConfirm: () => void;
  onClose: () => void;
  conflictingShift: Shift;
  isClipboardScoreEnabled: boolean;
}

export function ShiftInviteConflictDialog(props: ShiftInviteConflictDialogProps) {
  const { isClipboardScoreEnabled, modalState, onConfirm, onClose, conflictingShift } = props;
  const conflictedFacilityName = conflictingShift.facility.name;
  const worker = useDefinedWorker();
  const { data: attendanceScoreProfile } = useGetAttendanceScoreProfile(worker?.userId);

  const reliabilityRankingExperiment = useReliabilityRankingExperiment({
    reliabilityScore: attendanceScoreProfile?.reliabilityScore,
    reliabilityScoreRank: attendanceScoreProfile?.reliabilityScoreRank,
  });

  const scoreType = getScoreType({
    isReliabilityRankingExperimentEnabled: reliabilityRankingExperiment.enabled,
    isClipboardScoreEnabled,
  });

  return (
    <CommonDialog
      modalState={modalState}
      title="You're booked for a conflicting shift"
      actions={
        <>
          <Button fullWidth variant="contained" color="primary" onClick={onConfirm}>
            View My Shifts
          </Button>
          <Button fullWidth variant="outlined" onClick={onClose}>
            Go Back
          </Button>
        </>
      }
    >
      <Stack spacing={1}>
        <Text paragraph>
          The shift you are invited to overlaps with your booked shift at {conflictedFacilityName}.
          You can cancel your other booked shift to accept this invite ({scoreType} penalties may
          apply).
        </Text>
      </Stack>
    </CommonDialog>
  );
}
